<template>
  <v-app>
    <v-container>
      <v-card>
        <v-toolbar flat dark color="#333">

          <v-toolbar-title>Аналитика</v-toolbar-title>

          <template v-slot:extension>
            <v-tabs
                v-model="tab"
                align-with-title
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab
                  v-for="item in items"
                  :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div style="display:flex;align-items: center;"><span>Выгрузить: </span><DatePeriod v-model="periodDownload"/>
                  <v-btn class="mr-2" :href="$store.state.server.genUrl('ordersSeo/downloadNewClients', {period: periodDownload, dateType, statusTypes})">Статистика</v-btn>
                  <v-btn :href="$store.state.server.genUrl('ordersSeo/downloadOrders', {period: periodDownload})">Заказы</v-btn>
                </div>
                <v-select @change="loadNew" style="display: inline-block;width: 200px" class="ml-2" outlined v-model="dateType" :items="[{name: 'Дата создания', value: 'datetime_create'},{name: 'Дата исполнения', value: 'datetimeclean'},{name: 'Дата оплаты/отмены', value: 'lastupdate'}]" item-text="name" item-value="value"/>
                <YearMonth @change="loadNew" style="display: inline-flex" v-model="month"/>
                <v-select @change="loadNew" placeholder="Статусы" style="display: inline-block;width: 200px" class="ml-2" outlined v-model="statusTypes" multiple :items="[{name: 'Исполнено', value: 'finished'},{name: 'Отменено', value: 'canceled'},{name: 'Активно', value: 'active'}]" item-text="name" item-value="value"/>
                <v-btn class="ml-2" :loading="saveLoading" :disabled="!Object.keys(toSave).length" @click="save">
                  Сохранить
                </v-btn>
                <v-select style=""
                          @change="$root.saveData('ordersSeo', 'newClients_headers', visibleColumnsNew)" outlined
                          multiple
                          :items="headersNew" v-model="visibleColumnsNew"/>
                <v-data-table
                    hover
                    :items="rowsNew"
                    :headers="headersNew.filter(h=>visibleColumnsNew.includes(h.value))"
                    :loading="loading"
                    :footer-props="{'items-per-page-options':[-1]}"
                    no-data-text="Информации нет"
                    loading-text="Загрузка..."
                    mobile-breakpoint="0">
                  <template v-slot:item.new_createdIn="{item, index}">
                    <td style="white-space: nowrap">{{ item.new_createdIn_web || 0 }} / {{
                        item.new_createdIn_app || 0
                      }} /
                      {{ item.new_createdIn_call || 0 }} /
                      {{ item.new_createdIn_other || 0 }}
                    </td>
                  </template>
                  <template v-slot:item.types="{item, index}">
                    <td style="white-space: nowrap">{{ item.new_type_standart_count || 0 }} /
                      {{ item.new_type_general_count || 0 }} / {{ item.new_type_remont_count || 0 }} /
                      {{ item.new_type_other_count || 0 }}
                    </td>
                  </template>
                  <template v-slot:item.client_cost="{item, index}">
                    <td style="white-space: nowrap">
                      {{
                        item.total_isp_count ? $root.printCost(Math.round(item.actual_budget / item.total_isp_count)) : 'N/A'
                      }}
                    </td>
                  </template>
                  <template v-slot:item.total_count="{item, index}">
                    <td style="white-space: nowrap">
                      <span>{{ item.total_count || 0 }} <span
                          v-html="percent(item.leads, item.total_count)"></span></span> /
                      <span style="color: #2eb85c">{{ item.total_isp_count || 0 }} <span
                          v-html="percent(item.total_count, item.total_isp_count)"></span></span> /
                      <span style="color: red">{{ item.total_canceled_count || 0 }} <span
                          v-html="percent(item.total_count, item.total_canceled_count)"></span></span>
                    </td>
                  </template>
                  <template v-slot:item.new_count="{item, index}">
                    <td style="white-space: nowrap">
                      <span>{{ item.new_count || 0 }} <span v-html="percent(item.leads, item.new_count)"></span></span>
                      /
                      <span style="color: #2eb85c">{{ item.new_isp_count || 0 }} <span
                          v-html="percent(item.new_count, item.new_isp_count)"></span></span> /
                      <span style="color: red">{{ item.new_canceled_count || 0 }} <span
                          v-html="percent(item.new_count, item.new_canceled_count)"></span></span>
                    </td>
                  </template>
                  <template v-slot:item.repeated_count="{item, index}">
                    <td style="white-space: nowrap">
                <span>{{ item.repeated_count || 0 }} <span
                    v-html="percent(item.leads, item.repeated_count)"></span></span> /
                      <span style="color: #2eb85c">{{ item.repeated_isp_count || 0 }} <span
                          v-html="percent(item.repeated_count, item.repeated_isp_count)"></span></span> /
                      <span style="color: red">{{ item.repeated_canceled_count || 0 }} <span
                          v-html="percent(item.repeated_count, item.repeated_canceled_count)"></span></span>
                    </td>
                  </template>
                  <template v-slot:item.subscription_count="{item, index}">
                    <td style="white-space: nowrap">
                      <span>{{ item.subscription_count || 0 }}</span> /
                      <span style="color: #2eb85c">{{ item.subscription_isp_count || 0 }}</span> /
                      <span style="color: red">{{ item.subscription_canceled_count || 0 }}</span>
                    </td>
                  </template>
                  <template v-slot:item.new_total="{item, index}">
                    <td style="white-space: nowrap">
                      <span style="color: #3399ff">{{ $root.printCost(item.new_total) }}</span> /
                      <span style="color: #3399ff">{{ $root.printCost(item.repeated_total) }}</span> /
                      <span style="color: #3399ff">{{ $root.printCost(item.total_total) }}</span>
                    </td>
                  </template>
                  <template v-slot:item.avg_cost="{item, index}">
                    <td style="white-space: nowrap">{{
                        $root.printCost(Math.round(item.new_total / item.new_isp_count))
                      }}
                    </td>
                  </template>
                  <template v-slot:item.actual_budget="{item, index}">
                    <td style="white-space: nowrap">
                      <v-text-field :disabled="item.source_id<0"
                                    @input="$set(toSave, `${item.source_id}_actual_budget`, item.actual_budget)"
                                    v-model="item.actual_budget"/>
                    </td>
                  </template>
                  <template v-slot:item.drr="{item, index}">
                    <td style="white-space: nowrap">
                      {{item.drr}}
                    </td>
                  </template>
                  <template v-slot:item.planned_budget="{item, index}">
                    <td style="white-space: nowrap">
                      <v-text-field :disabled="item.source_id<0"
                                    @input="$set(toSave, `${item.source_id}_planned_budget`, item.planned_budget)"
                                    v-model="item.planned_budget"/>
                    </td>
                  </template>
                  <template v-slot:item.leads="{item, index}">
                    <td style="white-space: nowrap">
                      <v-text-field :disabled="item.source_id<0"
                                    @input="$set(toSave, `${item.source_id}_leads`, item.leads)"
                                    v-model="item.leads"/>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <div class="mt-2 ml-2" style="display: inline-flex">Первая уборка клиента:</div>
            <v-btn class="ml-2 mt-2 v-btn-transp" style="display: inline-flex" :href="downloadURL">
              Выгрузить
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <DatePeriod :intervals="[]" @change="loadRep" v-model="period"/>

            <v-card flat>
              <v-card-text>
                <v-data-table
                    hover
                    :items="rowsRep"
                    :headers="headersRep"
                    :loading="loading"
                    :footer-props="{'items-per-page-options':[-1]}"
                    no-data-text="Информации нет"
                    loading-text="Загрузка..."
                    mobile-breakpoint="0"
                >
                  <template v-slot:item="{item, index}">
                    <tr>
                      <td style="white-space: nowrap">{{ $moment(+item.datetimeclean * 1000).format('D MMM YY') }}</td>
                      <td>
                        {{ item.utm_source }}
                        <v-chip style="max-width: 200px;" color="#9BF0F5" small v-if="item.promo">Промо
                          {{ item.promo }}
                        </v-chip>
                      </td>
                      <td style="white-space: nowrap">
                        <a style="color: #333;" target="_blank" :href="`#/orders/${item.n_id}`">
                          {{ item.createdInStr }} /
                          {{ $store.state.cleanTypes[item.type] }} /
                          <Rate style="display: inline-block" :value="+item.feedback"/>
                          /
                          {{ $root.printCost(item.total) }} /
                          {{ $root.printCost(item.totalCost - item.total) }}
                        </a>
                      </td>
                      <template v-for="i in ordersNum">
                        <template v-if="item.nextOrders[i-1]">
                          <td style="white-space: nowrap">
                            {{
                              Math.round((item.nextOrders[i - 1].datetimeclean - (i === 1 ? item.datetimeclean : item.nextOrders[i - 2].datetimeclean)) / (24 * 3600))
                            }}
                            дней
                          </td>
                          <td>{{ item.nextOrders[i - 1].utm_source }}
                            <div v-if="item.nextOrders[i - 1].sms" class="my-2"
                                 style="background: #eee;padding: 10px;border-radius: 10px">
                              <InfoTip value="Смс в течение недели до оформления заказа"/>
                              {{ item.nextOrders[i - 1].sms }}
                            </div>
                            <v-chip color="#9BF0F5" style="max-width: 200px;" small v-if="item.nextOrders[i - 1].promo"
                                    class="my-2">Промо {{ item.nextOrders[i - 1].promo }}
                            </v-chip>
                          </td>
                          <td style="white-space: nowrap">
                            <a style="color: #333;" target="_blank" :href="`#/orders/${item.nextOrders[i - 1].n_id}`">
                              {{ item.nextOrders[i - 1].createdInStr }} /
                              {{ $store.state.cleanTypes[item.nextOrders[i - 1].type] }} /
                              <Rate style="display: inline-block" :value="+item.nextOrders[i-1].feedback"/>
                              /
                              {{ $root.printCost(item.nextOrders[i - 1].total) }} /
                              {{ $root.printCost(item.nextOrders[i - 1].totalCost - item.nextOrders[i - 1].total) }}
                            </a>
                          </td>
                        </template>
                        <template v-else>
                          <td></td>
                          <td></td>
                          <td style="color: #aaa">Больше уборок не было</td>
                        </template>
                      </template>
                      <td style="white-space: nowrap">{{ $root.printCost(item.client_price) }} ({{ item.client_love }}
                        шт)
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <iframe
          src="http://metabase.cleanbros.ru/public/dashboard/acb603e8-8020-4cfe-ac14-913a82ce3700"
          frameborder="0"
          width="100%"
          allowtransparency
      ></iframe>
    </v-container>
  </v-app>
</template>

<script>
import YearMonth from "../../components/YearMonth";
import DatePeriod from "../../components/DatePeriod";
import Rate from "../../components/Rate";
import InfoTip from "../../components/InfoTip";

export default {
  name: "OrdersSeo",
  components: {InfoTip, Rate, DatePeriod, YearMonth},
  data() {
    let headersNew = [
      {text: 'Источник', value: 'source'},
      {text: 'Быст/Ген/Рем/Др', value: 'types'},
      {text: 'План бюджет', value: 'planned_budget'},
      {text: 'Факт бюджет', value: 'actual_budget'},
      {text: 'ДРР по новым/всем', value: 'drr'},
      {text: 'Сайт/App/Звонок/Другое', value: 'new_createdIn'},
      {text: 'Лиды', value: 'leads'},
      {text: 'Заказы всего (без подписок) (Всего/Не отмен/Отмен)', value: 'total_count'},
      {text: 'Заказы по новым клиентам', value: 'new_count'},
      {text: 'Заказы по повторным клиентам', value: 'repeated_count'},
      {text: 'Заказы по подписке', value: 'subscription_count'},
      {text: 'Отправлено в другой город', value: 'sent_to'},
      {text: 'Получено из другого города', value: 'received_from'},
      {text: 'Цена нового клиента', value: 'client_cost'},
      {text: 'Сумма по новым / повторным / новые+повторные+подписки', value: 'new_total'},
      {text: 'Средний чек', value: 'avg_cost'},
    ];
    // let headersRep = [
    //   {text: 'Дата 1-й уборки', value: 'datetime_first'},
    //   {text: 'Способ оформления', value: 'createdIn'},
    //   {text: 'Тип 1-й уборки', value: 'planned_budget'},
    //   {text: 'Оценка 1-й уборки', value: 'planned_budget'},
    //   {text: 'Сумма', value: 'planned_budget'},
    //   {text: 'Скидка', value: 'planned_budget'},
    //   {text: 'Стоимость клиента', value: 'planned_budget'},
    //   {text: 'Канал повторной продажи', value: 'planned_budget'},
    //   {text: 'Стоимость повторного привлечения', value: 'planned_budget'},
    //   {text: 'Срок повторного контакта после 1ой уборки', value: 'planned_budget'},
    //   {text: 'Конверсия во второй заказ', value: 'planned_budget'},
    //   {text: 'Конверсия во второй заказ', value: 'planned_budget'},
    // ];
    let headersRep = [
      {text: 'Дата 1-й уборки', value: 'order1_date'},
      {text: 'Источник', value: 'order1_source'},
      {text: 'Способ оформ / Тип / Оценка / Сумма / Скидка', value: 'order1_info'},
      {text: '2-я уборка через', value: 'order2_delay'},
      {text: 'Источник', value: 'order2_source'},
      {text: 'Способ оформ / Тип / Оценка / Сумма / Скидка', value: 'order2_info'},
      {text: '3-я уборка через', value: 'order3_delay'},
      {text: 'Источник', value: 'order3_source'},
      {text: 'Способ оформ / Тип / Оценка / Сумма / Скидка', value: 'order3_info'},
      {text: 'LTV', value: 'ltv'},
    ];
    return {
      ordersNum: 2,
      rowsNew: [],
      rowsRep: [],
      dateType: 'datetime_create',
      statusTypes: [],
      period: [this.$moment().subtract(6, 'months').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      periodDownload: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      toSave: {},
      tab: +this.$route.query.tab || 0,
      items: [
        'Новые клиенты', 'Повторные клиенты'
      ],
      saveLoading: false,
      visibleColumnsNew: this.$root.getData('ordersSeo', 'newClients_headers') || headersNew.map(h => h.value),
      month: [this.$moment().format('YYYY'), this.$moment().format('MM')],
      headersNew,
      headersRep,
      loading: true,
    }
  },
  methods: {
    loadNew() {
      this.loading = true;
      this.toSave = {};
      this.$store.state.server.request('ordersSeo/newClients', {month: this.month, dateType: this.dateType, statusTypes: this.statusTypes}, (data) => {
        this.loading = false;
        this.rowsNew = data.response;
        this.rowsRep = data.rowsRep;
      }, (data) => {
        this.loading = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    loadRep() {
      this.loading = true;
      this.toSave = {};
      this.$store.state.server.request('ordersSeo/repClients', {period: this.period}, (data) => {
        this.loading = false;
        this.rowsRep = data.response;
      }, (data) => {
        this.loading = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    percent(all, part) {
      return `<span style="color: #3399ff;font-size: 10px">${all ? Math.round((part || 0) / all * 100) : '---'}%</span>`;
    },
    save() {
      this.saveLoading = true;
      this.$store.state.server.request('ordersSeo/saveNewClients', {month: this.month, toSave: this.toSave}, (data) => {
        this.saveLoading = false;
        this.rowsNew = data.response;
        this.$root.notify('Информация сохранена');
        this.toSave = {};
      }, (data) => {
        this.saveLoading = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    load() {
      if (this.tab === 0) {
        this.loadNew();
      } else {
        this.loadRep();
      }
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    downloadURL() {
      let params = {period: this.period};
      params.csv = 1;
      return this.$store.state.server.genUrl('ordersSeo/repClients', params);
    }
  },
  watch: {
    tab() {
      if (+this.$route.query.tab !== this.tab)
        this.$router.push({query: {tab: this.tab}});
      this.load();
    }
  }
}
</script>
